// @ts-expect-error no types
import getHubletSuffix from 'forms-embed-utils-lib/hublets/getHubletSuffix';
// @ts-expect-error no types
import getHubletFromUrl from 'forms-embed-utils-lib/hublets/getHubletFromUrl';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import { hubletApi } from 'hub-http/middlewares/core';
// @ts-expect-error no types
import * as SurveyTypes from 'feedback-schema/constants/surveyTypes';
import { MOCK_CES_SUBMISSION_RESPONSE } from '../constants/MockData';
import { shouldSubmit } from '../utils/shouldSubmit';
import { parse } from '../utils/parse';
const FEEDBACK_API_BASE = 'feedback/public';
const SUBMISSION_API = {
  [SurveyTypes.CES]: `${FEEDBACK_API_BASE}/v1/submit/ces`,
  [SurveyTypes.CSAT]: `${FEEDBACK_API_BASE}/v1/submit/csat`,
  [SurveyTypes.NPS]: `${FEEDBACK_API_BASE}/v1/submit/nps`
};
const FOLLOW_UP_API = `${FEEDBACK_API_BASE}/v1/submission-follow-up`;
const suffix = getHubletSuffix(getHubletFromUrl(window.location));
const maybeGetTicketObjectReference = (surveyType, maybeTicketId) => surveyType === SurveyTypes.CES && maybeTicketId ? {
  objectReferenceId: maybeTicketId,
  objectReferenceType: 'TICKET'
} : undefined;
const createInitialSubmissionRequest = (surveyType, contactId, params) => ({
  surveyChannel: 'EMAIL',
  channel: 'EMAIL',
  contactId,
  portalId: parse(params.portalId),
  surveyId: parse(params.surveyId),
  value: parse(params.rating),
  objectReference: maybeGetTicketObjectReference(surveyType, params.ticketId)
});
const createSubmissionFollowUpRequest = (params, {
  email,
  portalId
}, feedbackResponse, surveyType) => ({
  email,
  followUp: feedbackResponse.followUp,
  objectReference: maybeGetTicketObjectReference(surveyType, params.ticketId),
  portalId,
  rating: feedbackResponse.rating,
  submissionId: feedbackResponse.submissionId,
  surveyId: params.surveyId,
  surveyType
});
export const submit = (surveyType, contactId, params) => {
  if (!shouldSubmit(params)) {
    return Promise.resolve(MOCK_CES_SUBMISSION_RESPONSE);
  }
  return noAuthApiClient.post(SUBMISSION_API[surveyType], {
    api: hubletApi(`api${suffix}`, 'hubapi'),
    data: createInitialSubmissionRequest(surveyType, contactId, params)
  });
};
export const submitFollowUp = (params, surveyConfig, feedbackResponse, surveyType) => {
  if (!shouldSubmit(params)) {
    return Promise.resolve();
  }
  const submissionFollowUpRequest = createSubmissionFollowUpRequest(params, surveyConfig, feedbackResponse, surveyType);
  return noAuthApiClient.post(`${FOLLOW_UP_API}/${surveyType.toLowerCase()}`, {
    api: hubletApi(`api${suffix}`, 'hubapi'),
    data: submissionFollowUpRequest,
    withCredentials: false
  });
};